import reset from '@seft/design-tokens/assets/css/reset';
import { css } from 'lit';

export const active = `
  :host([active]):host-context(seft-menu[layout=horizontal]) [part=item] {
    border-bottom-width: 6px;
  }
  :host([active]):host-context(seft-menu[layout=horizontal]) [part=item] ::slotted(*) {
    padding-bottom: calc(var(--gutter) - 6px);
  }
`;
export const base = `
  :host {
    --gutter: var(--brand-size-component-gutter);
  }
`;
export const content = `
  [part=item] ::slotted(*) {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--gutter);
    color: rgb(var(--brand-color-text-secondary));
  }
`;
export const hover = `
  :host(:not([active])) [part=item]:hover ::slotted(*) {
    color: rgba(var(--brand-color-text-secondary) / 0.6);
  }
`;
export const item = `
  [part=item] {
    position: relative;
    cursor: pointer;
    height: 100%;
    width: 100%;
    z-index: 0;
    display: flex;
    flex-direction: column;

    transition: background-color 0.15s ease-in;
  }
  :host-context(seft-menu[layout=horizontal]) [part=item] {
    transition: border-width 0.15s ease-in;
    border-bottom-color: rgb(var(--brand-color-text-secondary));
    border-bottom-style: inset;
    border-bottom-width: 0px;
  }
`;

export default [
  css([reset] as ReadonlyArray<string> as TemplateStringsArray),
  css([base] as ReadonlyArray<string> as TemplateStringsArray),
  css([item] as ReadonlyArray<string> as TemplateStringsArray),
  css([content] as ReadonlyArray<string> as TemplateStringsArray),
  css([hover] as ReadonlyArray<string> as TemplateStringsArray),
  css([active] as ReadonlyArray<string> as TemplateStringsArray),
];
