import { html, LitElement, TemplateResult } from 'lit';
// eslint-disable-next-line import/extensions
import { customElement } from 'lit/decorators.js';
import styles from './styles';

/**
 * Creates a MenuItemElement for ...
 * @since 0.1.0-0
 * @status unstable
 * @tag seft-menu-item
 */
@customElement('seft-menu-item')
export class MenuItemElement extends LitElement {
  static override styles = styles;

  static override shadowRootOptions: ShadowRootInit = { mode: 'open', delegatesFocus: true };

  /**
   * Renders the primary DOM for Menu
   * @returns
   */
  protected override render(): TemplateResult {
    return html`
      <div part="item">
        <slot></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'seft-menu-item': MenuItemElement;
  }
}

export default MenuItemElement;
