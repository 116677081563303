import reset from '@seft/design-tokens/assets/css/reset';
import { css } from 'lit';

export const base = `
  :host {
    display: block;
  }
`;

export default [
  css([reset] as ReadonlyArray<string> as TemplateStringsArray),
  css([base] as ReadonlyArray<string> as TemplateStringsArray),
];
