import { html, LitElement, TemplateResult } from 'lit';
// eslint-disable-next-line import/extensions
import { customElement, property } from 'lit/decorators.js';
import styles from './styles';

export enum MenuLayout {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

/**
 * Creates a MenuElement for ...
 * @since 0.1.0-0
 * @status unstable
 * @tag seft-menu
 */
@customElement('seft-menu')
export class MenuElement extends LitElement {
  static override styles = styles;

  static override shadowRootOptions: ShadowRootInit = { mode: 'open', delegatesFocus: true };

  @property({ type: String, reflect: true }) layout: MenuLayout = MenuLayout.Vertical;

  /**
   * Renders the primary DOM for Menu
   * @returns
   */
  protected override render(): TemplateResult {
    return html` <slot></slot> `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'seft-menu': MenuElement;
  }
}

export default MenuElement;
