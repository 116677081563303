import reset from '@seft/design-tokens/assets/css/reset';
import { css } from 'lit';

export const base = `
  :host {
    height: 100%;
    width: 100%;
  }
  [part=nav] {
    height: 100%;
  }
`;
export const links = `
  a {
    text-decoration: none;
  }
`;

export default [
  css([reset] as ReadonlyArray<string> as TemplateStringsArray),
  css([base] as ReadonlyArray<string> as TemplateStringsArray),
  css([links] as ReadonlyArray<string> as TemplateStringsArray),
];
