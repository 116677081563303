import reset from '@seft/design-tokens/assets/css/reset';
import { css } from 'lit';

export const base = `
  :host {
    --gutter: var(--brand-size-component-gutter);
    --height: 64px;

    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    isolation: isolate;
  }
`;
export const header = `
  header {
    width: 100%;
    display: flex;
    height: var(--height);
    z-index: 1;

    background-color: rgb(var(--brand-color-background));
    border-bottom: 1px solid rgb(var(--brand-color-background-secondary));
    box-shadow: 0px 0px 3px rgb(var(--brand-color-background-secondary));
  }
`;
export const logo = `
  ::slotted(a),
  [slot=logo] {
    position: relative;
    flex: 0 0 auto;
    background-color: rgb(var(--brand-color-background) / 50%);
  }
  img {
    left: 0;
    top: 0;
    height: var(--height);
    width: var(--height);
    padding: var(--gutter);
  }
  [slot=logo]:hover {
    background-color: rgb(var(--brand-color-background) / 75%);
  }
`;
export const nav = `
  [part=nav] {
    height: 100%;
    flex: 1 1 100%;
  }
`;
export const signIn = `
  #signin {
    display: block;
    padding: var(--gutter);
    flex: 1 1 auto;
  }
  #signin > seft-button::part(label) {
    color: rgb(var(--brand-color-text-secondary));
  }
`;

export default [
  css([reset] as ReadonlyArray<string> as TemplateStringsArray),
  css([base] as ReadonlyArray<string> as TemplateStringsArray),
  css([header] as ReadonlyArray<string> as TemplateStringsArray),
  css([logo] as ReadonlyArray<string> as TemplateStringsArray),
  css([nav] as ReadonlyArray<string> as TemplateStringsArray),
  css([signIn] as ReadonlyArray<string> as TemplateStringsArray),
];
