import reset from '@seft/design-tokens/assets/css/reset';
import { css } from 'lit';

export const base = `
  :host {
    --gutter: var(--brand-size-component-gutter);
    --height: var(--brand-size-component-min-height);
    --width: var(--brand-size-component-min-width);
    --hover-opacity: 20%;
  }
  :host, #button, [part=label] {
    font-weight: 600;
  }
`;
export const button = `
  [part=button] {
    position: relative;
    min-height: var(--height);
    min-width: var(--width);
    border-radius: var(--height);
    border-style: none;
    border-color: rgb(var(--brand-color-border));
    border-width: 1px;
    padding: calc(var(--gutter) / 2) calc(var(--gutter) * 2);
    background-color: transparent;
    white-space: nowrap;
    z-index: 0;
  }
  [part=button]:not([disabled]) {
    cursor: pointer;
  }
  :host([type=filled]) [part=button] {
    color: rgb(var(--brand-color-background));
    background-color: rgb(var(--brand-color-primary));
  }
  :host([type=outlined]) [part=button] {
    border-style: solid;
  }
  :host([type=outlined]) [part=button],
  :host([type=text]) [part=button] {
    color: rgb(var(--brand-color-primary));
    --hover-opacity: 50%;
  }
  [part=button]:not([disabled]):hover::after,
  [part=button]:not([disabled]):focus::after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    border-radius: inherit;

    background-color: rgb(var(--brand-color-background-secondary) / var(--hover-opacity));
  }
  :host([disabled]) [part=button] {
    color: rgb(var(--brand-color-text-disabled));
    background-color: rgb(var(--brand-color-background-disabled));
    border-color: rgb(var(--brand-color-text-disabled));
  }

  @media (prefers-color-scheme: dark) {
    :host([type=outlined]) [part=button],
    :host([type=text]) [part=button] {
      --hover-opacity: 90%;
    }
  }
`;
export const label = `
  [part=label] {
    pointer-events: none;
  }
`;
export const icon = `
  :host([type=icon]) [part=button] {

  }
  [part=button] .icon {

  }
  [part=button] .icon--leading {

  }
  [part=button] .icon--trailing {

  }
`;

export default [
  css([reset] as ReadonlyArray<string> as TemplateStringsArray),
  css([base] as ReadonlyArray<string> as TemplateStringsArray),
  css([button] as ReadonlyArray<string> as TemplateStringsArray),
  css([label] as ReadonlyArray<string> as TemplateStringsArray),
  css([icon] as ReadonlyArray<string> as TemplateStringsArray),
];
