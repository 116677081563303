import { html, LitElement, TemplateResult } from 'lit';
// eslint-disable-next-line import/extensions
import { customElement, state } from 'lit/decorators.js';
import RouterService, { RouterService as RouterServiceStatic } from '@seft/router-service';
import type { Route, RouteParams } from '@seft/router-service/types';
import styles from './styles';

/**
 * Handles the click actions for nav links. These links have both a click handler and an href within the context of an
 *  <a> tag so we must prevent default before triggering the router to navigate the user. This is done so click actions
 *  can be handled as a SPA for performance while provided a proper href to support screen readers and alternate click
 *  options such as middle mouse to open in new tab.
 * @param name
 * @param e
 */
function clickHandler(name: string, e: Event) {
  e.preventDefault();
  void RouterService.navigate(name);
}
/**
 * Generates a menu item for a given entry in the page registry.
 * @param registryEntry
 * @returns
 */
function generateNavItem(currentPage: string | null, { name, pattern, navItem }: Route<string>): TemplateResult | null {
  const active = currentPage === name;
  return navItem
    ? html`
        <seft-menu-item ?active=${active} part="menu-item">
          <a part="menu-link" href=${RouterServiceStatic.generatePath(pattern)} @click=${clickHandler.bind(null, name)}>
            ${name}
          </a>
        </seft-menu-item>
      `
    : null;
}
/**
 * Renders the Nav links for Seft pages.
 * @since 0.1.0-0
 * @status unstable
 * @tag seft-pages-nav
 * @listens RouterService#route-change
 */
@customElement('seft-pages-nav')
export class PagesNavElement extends LitElement {
  static override styles = styles;

  static override shadowRootOptions: ShadowRootInit = { mode: 'open', delegatesFocus: true };

  private routeChangeHandler = (e: Event) => {
    const { detail } = e as CustomEvent<{ name: string; params?: RouteParams }>;
    this._currentPage = detail.name;
  };

  @state() private _currentPage: string | null = null;

  @state() private _routes: Route<string>[] = [];

  override connectedCallback() {
    super.connectedCallback();

    window.addEventListener('route-change', this.routeChangeHandler);
    this._currentPage = RouterService.currentRoute?.name || null;
    this._routes = RouterService.list();
  }

  override disconnectedCallback() {
    super.disconnectedCallback();

    window.removeEventListener('route-change', this.routeChangeHandler);
  }

  /**
   * Renders the primary DOM for Pages
   * @returns
   */
  protected override render(): TemplateResult {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { _routes } = this;
    const mapping = generateNavItem.bind(null, this._currentPage);

    return html` <seft-menu id="nav" layout="horizontal" part="nav"> ${_routes.map(mapping)} </seft-menu> `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'seft-pages-nav': PagesNavElement;
  }
}

export default PagesNavElement;
