import reset from '@seft/design-tokens/assets/css/reset';
import { css } from 'lit';

export const base = `
  :host {
    align-self: stretch;
    font-size: var(--brand-size-text);
    isolation: isolate;
    background-color: rgb(var(--brand-color-primary));
  }
`;
export const menu = `
  :host {
    display: flex;
    white-space: nowrap;
  }
  :host([layout=vertical]) {
    flex-direction: column;
  }
  :host([layout=horizontal]) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
  }
`;
export const items = `
  :host([layout=vertical]) > * {
    flex: 1 1 auto;
  }
  :host([layout=horizontal]) > * {
    flex: 0 1 auto;
  }
`;

export default [
  css([reset] as ReadonlyArray<string> as TemplateStringsArray),
  css([base] as ReadonlyArray<string> as TemplateStringsArray),
  css([menu] as ReadonlyArray<string> as TemplateStringsArray),
  css([items] as ReadonlyArray<string> as TemplateStringsArray),
];
