import reset from '@seft/design-tokens/assets/css/reset';
import { css } from 'lit';

export const base = `
  :host {
    --gutter: var(--brand-size-component-gutter);

    display: block;
    isolation: isolate;

    padding: var(--gutter);
    border-top: 1px solid rgb(var(--brand-color-background-secondary) / 0.1);
    box-shadow: 0px 0px 3px rgb(var(--brand-color-background-secondary) / 0.3);
    background-color: inherit;
  }
`;

export default [
  css([reset] as ReadonlyArray<string> as TemplateStringsArray),
  css([base] as ReadonlyArray<string> as TemplateStringsArray),
];
