import { html, nothing, LitElement, TemplateResult } from 'lit';
// eslint-disable-next-line import/extensions
import { customElement, property, query, queryAssignedElements } from 'lit/decorators.js';
// eslint-disable-next-line import/extensions
import { html as staticHtml, literal } from 'lit/static-html.js';
// eslint-disable-next-line import/extensions
import { ClassInfo, classMap } from 'lit/directives/class-map.js';
import styles from './styles';
import { ButtonTypes, IconPositions } from './types';

function getButtonClasses(icons: HTMLElement[] = [], iconPosition?: IconPositions): ClassInfo {
  return {
    icon: icons.length > 0,
    'icon--leading': iconPosition === IconPositions.Leading,
    'icon--trailing': iconPosition === IconPositions.Trailing,
  };
}

function renderIcon(): TemplateResult {
  return html`<slot name="icon"></slot>`;
}

/**
 * Creates a ButtonElement for standardized click based user interactions, also covers the link element case when
 *  provided an href property.
 * @since 0.1.0-0
 * @status unstable
 * @tag seft-button
 */
@customElement('seft-button')
export class ButtonElement extends LitElement {
  static override styles = styles;

  static override shadowRootOptions: ShadowRootInit = { mode: 'open', delegatesFocus: true };

  @query('#button') protected button!: HTMLElement;

  @queryAssignedElements({ slot: 'icon', flatten: true }) protected icons?: HTMLElement[];

  @property({ attribute: 'data-aria-expanded', noAccessor: true })
  override ariaExpanded!: 'true' | 'false';

  @property({ attribute: 'data-aria-has-popup', noAccessor: true })
  override ariaHasPopup!: 'false' | 'true' | 'menu' | 'listbox' | 'tree' | 'grid' | 'dialog';

  @property({ attribute: 'data-aria-label', noAccessor: true })
  override ariaLabel!: string;

  /**
   * Whether or not the button is disabled.
   */
  @property({ type: Boolean, reflect: true }) disabled = false;

  /**
   * The URL for the link button.
   */
  @property() href?: string;

  /**
   * Target property to use with a link button.
   */
  @property() target?: string;

  /**
   * Controls display position of an icon provided through the `slot=icon`. No preference will ignore the icon, the
   *  exception being when `type=ButtonType.Icon` which renders only an icon so this property will get ignored.
   */
  @property({ type: String }) iconPosition?: IconPositions;

  /**
   * Determines the rendering type of the button, defaults to ButtonTypes.Filled which is the most prominent call to
   *  action button type.
   */
  @property({ type: String, reflect: true }) type: ButtonTypes = ButtonTypes.Filled;

  override focus() {
    this.button.focus();
  }

  override blur() {
    this.button.blur();
  }

  /**
   * Renders the primary DOM for Button
   * @returns
   */
  protected override render(): TemplateResult {
    const { ariaLabel, ariaExpanded, ariaHasPopup, disabled, href, iconPosition, icons, target } = this;
    const tag = href ? literal`a` : literal`button`;

    return staticHtml`
      <${tag} part=button
        class="${classMap(getButtonClasses(icons, iconPosition))}"
        ?disabled=${disabled && !href}
        aria-label=${ariaLabel || nothing}
        aria-expanded=${ariaExpanded || nothing}
        aria-haspopup=${ariaHasPopup || nothing}
        href=${href || nothing}
        target=${target || nothing}
      >
        ${iconPosition === IconPositions.Leading ? renderIcon() : null}
        <span part=label><slot></slot></span>
        ${iconPosition === IconPositions.Trailing ? renderIcon() : null}
      </${tag}>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'seft-button': ButtonElement;
  }
}

export default ButtonElement;
