import { html, LitElement, TemplateResult } from 'lit';
// eslint-disable-next-line import/extensions
import { customElement } from 'lit/decorators.js';
// import darkLogo from '@seft/design-tokens/assets/images/seft-letterform-dark.webp';
// import lightLogo from '@seft/design-tokens/assets/images/seft-letterform.webp';
import styles from './styles';

/**
 * Creates a FooterElement for displaying information across all pages.
 * @since 0.1.0-0
 * @status unstable
 * @tag seft-footer
 */
@customElement('seft-footer')
export class FooterElement extends LitElement {
  static override styles = styles;

  static override shadowRootOptions: ShadowRootInit = { mode: 'open', delegatesFocus: true };

  /**
   * Renders the primary DOM for Header
   * @returns
   */
  protected override render(): TemplateResult {
    return html` <slot></slot> `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'seft-footer': FooterElement;
  }
}

export default FooterElement;
