import { css } from 'lit';
import reset from '@seft/design-tokens/assets/css/reset';
import placeholder from '@seft/design-tokens/assets/icons/person.svg';

export const base = `
  @keyframes fadeIn { from { opacity: 0; } to { opacity: 1; }}

  :host {
    --gutter: var(--brand-size-component-gutter);

    display: block;
    z-index: 1;
    position: relative;
    user-select: none;
    height: var(--brand-size-component-min-height);
    width: var(--brand-size-component-min-width);
  }
  :host(:not([menudirection=none])) {
    cursor: pointer;
  }
  :host([hasuser]) {
    background-image: url('${placeholder}');
    backdrop-filter: contrast(0.5);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%;
    border: 1px solid rgb(var(--brand-color-border));
  }
  #photo {
    height: 100%;
    aspect-ratio: 1 / 1;
    border-radius: inherit;

    opacity: 0;
    animation: fadeIn ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.3s;
  }
  #menu {
    position: absolute;
    top: calc(100% + var(--gutter));
    background-color: rgb(var(--brand-color-background));
    z-index: 1;
    border: 1px solid rgb(var(--brand-color-border));
  }
  #menu[direction=right] {
    left: 0;
  }
  #menu[direction=left] {
    right: 0;
  }
`;

export default [
  css([reset] as ReadonlyArray<string> as TemplateStringsArray),
  css([base] as ReadonlyArray<string> as TemplateStringsArray),
];
