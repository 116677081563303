import { html, LitElement, TemplateResult } from 'lit';
// eslint-disable-next-line import/extensions
import { customElement, state } from 'lit/decorators.js';
import RouterService from '@seft/router-service';
import type { RouteParams } from '@seft/router-service/types';
import styles from './styles';

/**
 * Creates an element for coordinating page rendering and loading as well as monitoring route changes.
 * @since 0.1.0-0
 * @status unstable
 * @tag seft-pages
 */
@customElement('seft-pages')
export class PagesElement extends LitElement {
  static override styles = styles;

  static override shadowRootOptions: ShadowRootInit = { mode: 'open', delegatesFocus: true };

  @state() page?: TemplateResult | null;

  @state() loading = true;

  override connectedCallback(): void {
    super.connectedCallback();
    RouterService.list().forEach((route) => {
      RouterService.attachCallback(route.name, this.generateCallback(route.importer, route.renderer));
    });
    try {
      void RouterService.handle(window.location.pathname);
    } catch {
      this.page = null;
    }
  }

  /**
   * Renders the primary DOM for Pages
   * @returns
   */
  protected override render(): TemplateResult {
    const { page } = this;

    return html`${page}`;
  }

  /**
   * Converts the importer / renderer references from the registry into a Route friendly callback method while
   *  injecting local setters for monitoring loading state and updating the DOM.
   * @param importer
   * @param page
   * @returns
   */
  private generateCallback(importer: () => Promise<unknown>, renderer: (params?: RouteParams) => unknown) {
    return async (params?: RouteParams) => {
      try {
        this.loading = true;
        await importer();
        this.page = renderer(params) as TemplateResult;
      } catch (e) {
        this.page = html`Error loading page`;
      } finally {
        this.loading = false;
      }
    };
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'seft-pages': PagesElement;
  }
}

export default PagesElement;
