import { html, LitElement, TemplateResult } from 'lit';
// eslint-disable-next-line import/extensions
import { customElement, property, state } from 'lit/decorators.js';
import type { User } from '@seft/auth-service/types';
import styles from './styles';
import { MenuDirection } from './types';
import '@seft/menu';

export interface AvatarProps {
  user?: User | null;
  menuDirection: MenuDirection;
}

/**
 * Removes the target from the DOM.
 * @param { target }
 */
function removeSelf({ target }: Event) {
  (target as HTMLElement)?.remove();
}
/**
 * Creates an avatar image based on a qualified photo URL and registers a click handler if one is provided. If the
 *  user image fails it will remove itself falling back to the generic placeholder image.
 * @param photoURL
 * @returns
 */
function renderAvatar(photoURL: string): TemplateResult | null {
  return html`<img id="photo" alt="User Avatar" referrerpolicy="no-referrer" src=${photoURL} @error=${removeSelf} />`;
}
/**
 * Renders the menu, functionality can be disabled by setting menuDirection to MenuDirection.None. Otherwise
 *  menuDirection will simply set the direction the menu should overflow (left vs right).
 * @param menuDirection
 * @param showMenu
 * @returns
 */
function renderMenu(menuDirection: MenuDirection, showMenu = false): TemplateResult | null {
  return showMenu && menuDirection !== MenuDirection.None
    ? html`
        <seft-menu id="menu" direction="${menuDirection}">
          <slot name="menu-item"></slot>
        </seft-menu>
      `
    : null;
}

/**
 * Creates a AvatarElement for rendering a profile picture from a User object.
 * @since 0.1.0-0
 * @status unstable
 * @tag seft-avatar
 * @todo close the menu when focus leaves the avatar element / sub-elements
 * @todo close the menu when an item is chosen from the menu
 */
@customElement('seft-avatar')
export class AvatarElement extends LitElement implements AvatarProps {
  static override styles = styles;

  static override shadowRootOptions: ShadowRootInit = { mode: 'open', delegatesFocus: true };

  private _user: User | null = null;

  set user(user: User | null) {
    // eslint-disable-next-line no-underscore-dangle
    this._user = user;
    this.hasUser = !!user;
  }

  get user(): User | null {
    // eslint-disable-next-line no-underscore-dangle
    return this._user;
  }

  @state() private showMenu = false;

  @property({ type: Boolean, reflect: true }) hasUser = false;

  @property({ type: String, reflect: true }) menuDirection: MenuDirection = MenuDirection.None;

  override connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('click', this.toggleMenu);
    this.addEventListener('keyup', this.toggleMenu);
  }

  override disconnectedCallback(): void {
    super.disconnectedCallback();
    this.removeEventListener('click', this.toggleMenu);
    this.removeEventListener('keyup', this.toggleMenu);
  }

  protected override render(): TemplateResult {
    const { menuDirection, showMenu, user } = this;
    return html`${user?.photoURL && renderAvatar(user.photoURL)} ${renderMenu(menuDirection, showMenu)}`;
  }

  /**
   * Toggles the menu open/closed.
   */
  private toggleMenu = () => {
    this.showMenu = !this.showMenu;
  };
}

declare global {
  interface HTMLElementTagNameMap {
    'seft-avatar': AvatarElement;
  }
}

export default AvatarElement;
